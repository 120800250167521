import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { themeSwitchFn } from '../../redux/slice/shareThemeSlice';
import { setCookie, getCookie } from './SetCookie';
const ThemeSwitch = () => {
    const dispatch = useDispatch();
    const [theme, setTheme] = useState('default');

    useEffect(() => {
        // 這裡使用|| 判斷如 null, undefined, 空字符串 '', 0, false, 或 NaN
        // 如果是?? 它僅在左側值是 null 或 undefined 時，返回右側的值。
        const cookieData = getCookie('theme') || 'default';
        dispatch(themeSwitchFn(cookieData));
        setTheme(cookieData)
    }, []);

    return (
        <SwitchDiv>
            {
                theme === 'dark' &&
                <BtnCursor>
                    <FontAwesomeIcon icon={faSun} style={{ fontSize: 'x-large' }}
                        onClick={() => {
                            dispatch(themeSwitchFn('default'))
                            setTheme('default')
                            setCookie('theme', 'default', 180)
                        }}
                    />
                </BtnCursor>
            }
            {
                (!theme || theme === 'default') &&
                <BtnCursor>
                    <FontAwesomeIcon icon={faMoon} style={{ fontSize: 'x-large' }}
                        onClick={() => {
                            dispatch(themeSwitchFn('dark'))
                            setTheme('dark')
                            setCookie('theme', 'dark', 180)
                        }}
                    />
                </BtnCursor>

            }
        </SwitchDiv>

    )
}

export default ThemeSwitch;

const SwitchDiv = styled.div`
    margin-left: auto;
`;
const BtnCursor = styled.div`
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`;